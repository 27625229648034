import ArticlePreview from '@components/article-preview'
import { graphql } from 'gatsby'
import React from 'react'
import { Container, Row } from 'react-bootstrap'
import Helmet from 'react-helmet'
import '../assets/scss/gatstrap.scss'

const HomePage = ({ data }) => {
  const seo = data.allContentfulGlobalSeo.edges[0].node
  const siteTitle = data.site.siteMetadata.title
  const posts = data.allContentfulBlogPost.edges
  const pageContent = data.allContentfulPages.edges[0].node

  return (
    <div className="wrapper bg-dark">
      <Helmet
        title={seo.globalSeoTitle}
        meta={[
          { name: 'description', content: `${seo.globalSeoDescription}` },
          {
            name: 'google-site-verification',
            content: 't5oEGfGSEWacfye53VaeBZp8dvH88UiqgGryPzf-s_0',
          },
        ]}
      />
      <header
        className="text-white"
        dangerouslySetInnerHTML={{
          __html: pageContent.header.childMarkdownRemark.html,
        }}
      />

      <section
        className="text-white"
        dangerouslySetInnerHTML={{
          __html: pageContent.btnArea.childMarkdownRemark.html,
        }}
      />
      <main
        className="bg-dark"
        dangerouslySetInnerHTML={{
          __html: pageContent.contents.childMarkdownRemark.html,
        }}
      />
      <Container className="my-5">
        <Row>
          {posts.map(({ node, index }) => {
            return <ArticlePreview article={node} key={node.slug} />
          })}
        </Row>
      </Container>
      <section
        className="text-white"
        dangerouslySetInnerHTML={{
          __html: pageContent.btnArea.childMarkdownRemark.html,
        }}
      />
      <footer
        className="bg-dark text-white"
        dangerouslySetInnerHTML={{
          __html: pageContent.footer.childMarkdownRemark.html,
        }}
      />
    </div>
  )
}

export default HomePage

export const pageQuery = graphql`
  query HomeQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulGlobalSeo {
      edges {
        node {
          globalSeoTitle
          globalSeoDescription
        }
      }
    }
    allContentfulPages {
      edges {
        node {
          pageTitle
          header {
            childMarkdownRemark {
              html
            }
          }
          btnArea {
            childMarkdownRemark {
              html
            }
          }
          contents {
            childMarkdownRemark {
              html
            }
          }
          footer {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
    allContentfulBlogPost(
      # filter: { node_locale: { eq: "ja" } }
      sort: { fields: [updatedAt], order: DESC }
      limit: 5
    ) {
      edges {
        node {
          title
          slug
          createdAt(formatString: "YYYY-MM-DD")
          updatedAt(formatString: "YYYY-MM-DD")
          tag {
            name
          }
          heroImage {
            fluid(maxWidth: 350, maxHeight: 196, resizingBehavior: SCALE) {
              ...GatsbyContentfulFluid_tracedSVG
            }
          }
          thumbnail {
            fluid(maxWidth: 350, maxHeight: 350, resizingBehavior: SCALE) {
              ...GatsbyContentfulFluid_tracedSVG
            }
          }
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`
