import { Link } from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'
import { Col, Row } from 'react-bootstrap'

export default ({ article, location }) => {
  return (
    <div className="col-12">
      <div className="card w-100 mb-5" key={article.slug}>
        <div className="card-body">
          <Row>
            <Col sm={12} md={3}>
              <Img alt="" fluid={article.thumbnail.fluid} />
            </Col>
            <Col sm={12} md={9}>
              <h3 className="card-title d-flex flex-column flex-md-row align-content-center justify-content-between p-3">
                <Link
                  to={`/blog/${article.slug}`}
                  className="text-orange font-weight-bold mb-md-0 mb-3"
                >
                  {article.title}
                </Link>
                <small className="badge badge-light">{article.updatedAt}</small>
              </h3>
              <div className="card-body">
                <div
                  className="text-white mb-3"
                  dangerouslySetInnerHTML={{
                    __html: article.description.childMarkdownRemark.html,
                  }}
                />
                {article.tag === null
                  ? ''
                  : article.tag.map((el, i) => (
                      <Link
                        className="badge badge-primary mr-3"
                        key={el.name}
                        to={`/tags/${el.name}`}
                      >
                        {el.name}
                      </Link>
                    ))}
              </div>
              <div className="text-right">
                <Link to={`/blog/${article.slug}`} className="btn">
                  続きを読む
                </Link>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  )
}
